import Vue from "vue";
import ElementUI from 'element-ui';
import "swiper/swiper.min.css"
import 'element-ui/lib/theme-chalk/index.css';
import App from "./App.vue";
import router from "./router";
import store from "./store";
import BaiduMap from "vue-baidu-map"

import '@/utils/rem.js'

import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/swiper-bundle.css'

Vue.use(VueAwesomeSwiper)


Vue.use(BaiduMap, {
  ak: 'xQyFDLdfWqSbzmWHnEN3VYmAWsKnh2G7'
})

import TIM from 'tim-js-sdk/tim-js-friendship.js';
import TIMUploadPlugin from 'tim-upload-plugin';
import TIMProfanityFilterPlugin from 'tim-profanity-filter-plugin';
let options = {
	SDKAppID: 1400649746 // 接入时需要将0替换为您的即时通信 IM 应用的 SDKAppID
};
// 创建 SDK 实例，`TIM.create()`方法对于同一个 `SDKAppID` 只会返回同一份实例
let tim = TIM.create(options); // SDK 实例通常用 tim 表示
// c27297d6641c68f9181f66ccb1bebff0f8666e64edd70eede58457279ff91195

// 设置 SDK 日志输出级别，详细分级请参见 setLogLevel  https://web.sdk.qcloud.com/im/doc/zh-cn/SDK.html#setLogLevel 接口的说明</a>
tim.setLogLevel(0); // 普通级别，日志量较多，接入时建议使用
// tim.setLogLevel(1); // release 级别，SDK 输出关键信息，生产环境时建议使用


// 注册腾讯云即时通信 IM 上传插件
tim.registerPlugin({
	'tim-upload-plugin': TIMUploadPlugin
});


// 注册腾讯云即时通信 IM 本地审核插件
tim.registerPlugin({
	'tim-profanity-filter-plugin': TIMProfanityFilterPlugin
});

Vue.prototype.TIM = TIM
Vue.prototype.tim = tim

store.dispatch("loadLocal");

Vue.use(ElementUI);
// 样式
import "@/style/reset.css";

import "./common/global/index";

Vue.config.productionTip = false;

new Vue({
  el: '#app',
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
