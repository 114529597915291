<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
document.getElementsByTagName("html")[0].style.fontSize =
  document.documentElement.clientWidth / 7.5 + "px";
window.onresize = function () {
  document.getElementsByTagName("html")[0].style.fontSize =
    document.documentElement.clientWidth / 7.5 + "px";
};
import { getusersig,changim } from '@/api/index.js';

export default {
  name: "App",
  watch: {
    $route(e) {
      /* 跳转到页面顶部 */
      window.scrollTo(0, 0);
    },
  },

  
  created() {},
  mounted() {
    let addr = this.$store.state.point;
    if (!addr.latitude) {
      this.$store.dispatch("getArea");
    }
        let y =  localStorage.getItem('LoginStatus')
   if(y&&Number(y)){
    let k = localStorage.getItem('userId')
    this.logins(k)
    console.log('k :>> ', k);
   }
  },
    methods: {

    //im 登录
		async	logins(uname) {

				// let that = this
				// console.log('hah', uname);
				// // let userSig = genTestUserSig(uname.toString()).userSig
				// 	let userSig =await	getusersig({memberId:uname})
				// let promise = this.tim.login({
				// 	userID: uname.toString(),
				// 	userSig: userSig
				// });
				// promise.then(function(imResponse) {
				// 	console.log(imResponse.data, `登录成功${uname}`); // 登录成功
				// 	console.log(imResponse.data.errorInfo);
				// 	changim({id:uname}).then(res=>{
						
				// 	})

				// 	if (imResponse.data.repeatLogin === true) {
				// 		// 标识帐号已登录，本次登录操作为重复登录。v2.5.1 起支持
				// 	}

				// }).catch(function(imError) {
				// 	console.warn('login error:', imError); // 登录失败的相关信息
				// });
			},
  },
};
</script>

<style lang="scss">
/* CSS Document */
body{
  font-size: 12px;
}
* {
  padding: 0;
  margin: 0;
  border: 0;
  box-sizing: border-box;
  font-family: "Microsoft YaHei", Arial, Helvetica, sans-serif;
}
ol,
ul {
  list-style: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1;
}
a {
  text-decoration: none;
  blr: expression(this.onFocus=this.blur());
}
i,
em {
  font-style: normal;
}

a:focus {
  outline: none;
}
a.hidefocus {
  outline: none;
}

input[type="text"],
input[type="submit"],
input[type="button"] {
  -webkit-appearance: none; /*去除iPhone input默认样式}*/
}
input {
  outline: none;
  background: none;
}
img {
  display: block;
}

.fl {
  float: left;
}
.fr {
  float: right;
}
.clear:after {
  display: block;
  content: "";
  clear: both;
  height: 0;
  overflow: hidden;
}
.clear {
  zoom: 1;
}
/**
注册字体
 */
 @font-face {
  font-family: "ALiHanYiZhiNengHeiTi";
  src: url("~@/assets/font/ALiHanYiZhiNengHeiTi-2.ttf");
}

</style>
